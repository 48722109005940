import { Component } from "@angular/core";
import { upworkRedirectComponent } from "../redirct/upwork-redirect.component";

@Component({
  selector: "about-component",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"]
})
export class AboutComponent {

  public asATexts = [
    "As a engineer, I have always enjoy designing and developing clean, efficient, and user friendly applicaitons/websites.",
    "As a co-worker, I find that one of the most enjoyable parts of my job is meeting and getting to know my fellow collegues.",
    "As a person, I seek to continually improve myself, to lift up those around me, and to always look forward to the future."
  ]
  public devSkills = [
    {
      type: "Frontend Development",
      skills: [
        "Angular / TypeScript / SCSS",
        "JavaScript / HTML / CSS ",
        "React / Vue.js / Bootstrap",
        "NPM / Vite",
        "Node.js",
        "SEO"
      ]
    }, {
      type: "Backend Development",
      skills: [
        ".Net / C# / Entity Framework",
        "RestAPIs / JSON / YAML",
        "Junit / Jmockit / Gradle",
        "SQL / MongoDB",
        "Server-side Paging",
        "Python / Django"
      ]
    }, {
      type: "Pipeline / Testing",
      skills: [
        "Continuous Integration (CI)",
        "End-to-end / Unit Testing",
        "Swagger / Chrome DevTools",
        "Robot framework / Selenium",
        "Octopus Deployment",
        "Azure DevOps"
      ]
    }, {
      type: "Other Skills/Tools",
      skills: [
        "Git/GitHub",
        "Scrum / Agile",
        "AWS / Azure / GCP",
        "VSCode / Visual Studio",
        "Internationalization",
        "Mobile-Friendly"
      ]
    }
  ]

  public experiences = [
    {
      title: "Software Engineer, Full Stack",
      how: "at",
      company: "CHS Inc",
      url: "https://www.chsinc.com/",
      duration: "2022 - Present",
      points: [
        "Develop and maintain the primary B2B application",
        "Construct responsive front-end components and custom backend database operations",
        "Write diverse SQL scripts run through all environments",
        "Maintain a robust unit and end-to-end testing suite through a continuous integration pipeline",
        "Actively provide code review feedback and train new team members",
        "Follow Scrum and Agile Methodologies as well as introduce and guide other team memberson best practices and procedures"
      ]
    },
    {
      title: "Undergraduate TA",
      how: "at",
      company: "The University of Minnesota(TC)",
      url: "https://twin-cities.umn.edu/",
      duration: "2021 - 2022",
      points: [
        "Led office hours and discussions for hundreds of students",
        "Graded multiple classes worth of homeworks and exams",
        "Taught algorithms and data structures to students",
        "Tutored individual students"
      ]
    }
  ]

  public educations = [
    {
      school: "University of Minnesota",
      url: "https://twin-cities.umn.edu",
      location: "Minneapolis, MN",
      timeFrame: "2022 - 2024",
      degree: "M.S. in Computer Science",
      gpa: "GPA: 4.0",
      award: "Magna Cum Laude"
    },
    {
      school: "University of Minnesota",
      url: "https://twin-cities.umn.edu/",
      location: "Minneapolis, MN",
      timeFrame: "2020 - 2023",
      degree: "B.S. in Computer Science",
      gpa: "GPA: 3.85",
      award: "Dean's List All Semesters"

    }
  ]

  public languages = [
    {
      level: "Fluent",
      names: [
        "English",
        "Fuzhounese"
      ]
    }, {
      level: "Intermediate",
      names: [
        "Mandarin"
      ]
    }, {
      level: "Basic",
      names: [
        "Spanish"
      ]
    }
  ]

  public hobbies =[
    "Video Games",
    "Board Games",
    "Weight Lifting",
    "Podcasts",
    "Reading",
    "Biking",
    "Sudoku",
    "Tennis"
  ]

  constructor() { }
}
