<div class="contact">
    <menu-component></menu-component>
    <div class="content">
        <div class="header">
            <h1> Hello Again! </h1>
            <h3>Let's start <span>talking</span>.</h3>
            <p>I look forward to hearing from you.</p>
        </div>
        <div class="contact-section">
            <div class="contact-header">
                <mat-icon svgIcon="account-box-outline"></mat-icon>
                <h2>Contact Me</h2>
            </div>
            <div class="contact-form-container">
                <div class="contact-form-header">
                    I’m interested in a wide range of opportunities, especially ambitious or large projects.
                    However, if you have other request or question, please don’t hesitate to send me a message
                    via this form or through my email address <a href="mailto: li001710@umn.edu">li001710&#64;umn.edu</a>.
                </div>
                <form name="form" [formGroup]="form" (ngSubmit)="submitForm()">
                    <div class="field" [ngClass]="{'is-invalid':form.get('name')?.touched && form.get('name')?.invalid}">
                        <label for="name">Name<span class="invalid-message">* is required</span></label>
                        <input type="text" name="name" class="input" id="name" [(ngModel)]="name" formControlName="name" required />
                    </div>
                    <div class="field" [ngClass]="{'is-invalid':form.get('email')?.touched && form.get('email')?.invalid}">
                        <label for="email">Email<span class="invalid-message">* {{form.get('email')?.errors?.required ? 'is required' : 'must be a valid email address'}}</span></label>
                        <input type="text" name="email" class="input" id="email" [(ngModel)]="email" formControlName="email" required />
                    </div>
                    <div class="field" [ngClass]="{'is-invalid':form.get('message')?.touched && form.get('message')?.invalid}">
                        <label for="message">Message<span class="invalid-message">* is required</span></label>
                        <textarea name="message" class="textarea" id="message" [(ngModel)]="message" formControlName="message" required></textarea>
                    </div>
                    <button type="submit">
                        <span *ngIf="sending">Sending .....</span>
                        <span *ngIf="!sending">Send message!</span>
                    </button>
                </form>
            </div>
        </div>
        <div class="message-response" [ngClass]="{'message-sent': !sending && messageSent}">
            <div class="message-success" *ngIf="messageSuccess">
                <p>Message Sent Successfully!</p>
                <p>Thank you for your message! I will get back to you as soon as I can.</p>
            </div>
            <div class="message-failed" *ngIf="!messageSuccess">
                <p>Message Failed To Send</p>
                <p>Sorry for the inconvience, please try sending your message directly to my email: <a href="mailto: li001710@umn.edu">li001710&#64;umn.edu</a>.</p>
            </div>
        </div>
        <div class="footer-spacing"></div>
    </div>
</div>
